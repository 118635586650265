<mat-form-field subscriptSizing="dynamic" class="select-form">
  <mat-label>Flux unit</mat-label>
  <mat-select
    [(ngModel)]="selectedFluxUnit"
    name="fluxUnit"
    class="select-unit"
    (selectionChange)="fluxUnitChanged($event.value)"
  >
    @for (fluxUnit of availableUnits; track fluxUnit) {
    <mat-option [value]="fluxUnit.value">{{ fluxUnit.viewValue }}</mat-option>
    }
  </mat-select>
</mat-form-field>
