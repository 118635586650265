<plotly-plot [divId]="divId" [useResizeHandler]="true" [data]="data" [layout]="layout" [config]="config"></plotly-plot>
<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Options" id="options-menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="downloadDataProductsClick()">
    <mat-icon>download</mat-icon>
    <span>Download Data Products</span>
  </button>
  <!--  <button mat-menu-item disabled>-->
  <!--    <mat-icon>browser_updated</mat-icon>-->
  <!--    <span>Download Shown Data</span>-->
  <!--  </button>-->
</mat-menu>
<div id="bottom-left-button-container">
  <!--  <button (click)="fetchGaiaDataClick(0)">Fetch Gaia 1</button>-->
  <!--  <button (click)="fetchGaiaDataClick(1)">Fetch Gaia 2</button>-->
  <!--  <button (click)="fetchGaiaDataClick(2)">Fetch Gaia 3</button>-->
  <!--  <button (click)="removeGaiaDataClick(0)">Remove Gaia 1</button>-->
  <!--  <button (click)="removeGaiaDataClick(1)">Remove Gaia 2</button>-->
  <!--  <button (click)="removeGaiaDataClick(2)">Remove Gaia 3</button>-->
  <!--  <button (click)="fetchJwstDataClick()">Fetch JWST</button>-->
  <!--  <button (click)="removeJwstDataClick()">Remove JWST</button>-->
  <!--  <button (click)="fetchCheopsDataClick(0)">Fetch CHEOPS 1</button>-->
  <!--  <button (click)="fetchCheopsDataClick(1)">Fetch CHEOPS 2</button>-->
  <!--  <button (click)="fetchXmmOmDataClick()">Fetch XMM-OM</button>-->
  <!--  <button (click)="fetchXmmEpicDataClick()">Fetch XMM-EPIC</button>-->
  <app-flux-unit-selector
    [data]="currentData"
    [isTimeView]="isTimeView"
    [sourceMap]="sourceUrls"
    (changeUnit)="fluxUnitChanged($event)"
  ></app-flux-unit-selector>
</div>
<div id="bottom-right-button-container">
  <mat-form-field subscriptSizing="dynamic" class="select-form" *ngIf="isTimeView">
    <mat-label>Time unit</mat-label>
    <mat-select
      [(ngModel)]="selectedTimeUnit"
      name="timeUnit"
      class="select-unit"
      (selectionChange)="timeUnitChanged($event.value)"
    >
      @for (timeUnit of timeUnits; track timeUnit) {
      <mat-option [value]="timeUnit.value">{{ timeUnit.viewValue }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <button (click)="switchWavelengthTimeView()">
    <img
      src="https://sky.esa.int/timeviz/assets/images/wavelength_time.png"
      alt="Switch between time view and SED view"
      class="icon"
      title="Switch between time view and SED view"
    />
  </button>
</div>
<mat-spinner *ngIf="isLoading" class="loading-spinner"></mat-spinner>
