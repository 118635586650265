import { Component, Input } from '@angular/core';
import { PlotlyService, PlotlySharedModule } from 'angular-plotly.js';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as Plotly from 'plotly.js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgIf } from '@angular/common';
import { FluxUnitSelectorComponent } from './fluxUnitSelector';
import { DataOptions, TimeVizBackendOptions, Unit } from './util/interfaces';
import { fluxElectronPerSecond, fluxPartsPerMillion, Jy, mJy, xmmFluxCts } from './util/fluxUnits';
import { AxisType } from 'plotly.js-dist-min';

@Component({
  selector: 'app-timeviz',
  standalone: true,
  imports: [
    PlotlySharedModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    NgIf,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    FluxUnitSelectorComponent
  ],
  templateUrl: './timeviz.component.html',
  styleUrl: './timeviz.component.scss'
})
export class TimevizComponent {
  private plotly: any;
  divId: string = 'timeviz-plotly-' + Math.random().toString(36);
  private resizeTimeout: any;
  isLoading: boolean = false;
  isTimeView = true;
  private isCombinedMissionsView = false;
  private numberOfDataCurrentlyLoading: number = 0;
  currentData = new Map<string, DataOptions>();
  private unitsHaveBeenChangedByUser: boolean = false;
  private selectedTimeScale: string = '';
  private dataAnimationDuration = 1000;
  private zoomDuration = 300;

  JDUnit = { value: 'jd', viewValue: 'JD' };
  MJDUnit = { value: 'mjd', viewValue: 'MJD' };
  timeUnits: Unit[] = [this.JDUnit, this.MJDUnit];
  //baseUrl = 'http://localhost:5000';
  baseUrl: string = '/tsview';

  sourceUrls = new Map<string, TimeVizBackendOptions>([
    [
      'Gaia-DR3',
      {
        mission: 'Gaia-DR3',
        url:
          this.baseUrl +
          '/ts/v1?mission=gaia&sourceID={id}&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        defaultTimeUnit: this.JDUnit.value,
        defaultFluxUnit: fluxElectronPerSecond,
        defaultTimeScale: 'tcb'
      }
    ],
    [
      'JWST-MID-IR',
      {
        mission: 'JWST-MID-IR',
        url:
          this.baseUrl +
          '/ts/v1?mission=jwst&obsID={id}&prodType=x1dints&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        defaultTimeUnit: this.JDUnit.value,
        defaultFluxUnit: Jy,
        defaultTimeScale: 'tdb'
      }
    ],
    [
      'XMM-EPIC',
      {
        mission: 'XMM-EPIC',
        url:
          this.baseUrl +
          '/ts/v1?mission=xmm-epic&sourceID={id}&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        defaultTimeUnit: this.JDUnit.value,
        defaultFluxUnit: xmmFluxCts,
        defaultTimeScale: 'tt'
      }
    ],
    [
      'XMM-OM',
      {
        mission: 'XMM-OM',
        url:
          this.baseUrl +
          '/ts/v1?mission=xmm-om&obsID={id}&sourceID={secondIdentifier}&extension=FTZ&level=PPS&instname=OM&name=TIMESR&expflag=X&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        defaultTimeUnit: this.JDUnit.value,
        defaultFluxUnit: xmmFluxCts,
        defaultTimeScale: 'tt'
      }
    ],
    [
      'CHEOPS',
      {
        mission: 'CHEOPS',
        url:
          this.baseUrl +
          '/ts/v1?mission=cheops&sourceID={id}&product_url={secondIdentifier}&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        defaultTimeUnit: this.JDUnit.value,
        defaultFluxUnit: fluxPartsPerMillion,
        defaultTimeScale: 'tt'
      }
    ]
  ]);

  selectedFluxUnit: string = mJy.value;

  fluxUnitChanged(newUnit: string) {
    this.selectedFluxUnit = newUnit;
    this.unitsHaveBeenChangedByUser = true;
    if (this.layout.yaxis != undefined) {
      this.layout.yaxis.range = [0, 0.00001];
    }
    this.fetchDataWithNewUnits();
  }

  fetchDataWithNewUnits() {
    const dataToFetch = new Map<string, DataOptions>();
    let anyDataLoadedInTheCorrectUnits = false;
    this.currentData.forEach((dataOptions, key) => {
      if (
        dataOptions.selectedFluxUnit !== this.selectedFluxUnit ||
        dataOptions.selectedTimeUnit != this.selectedTimeUnit ||
        dataOptions.selectedTimeScale != this.selectedTimeScale ||
        dataOptions.isTimeView != this.isTimeView
      ) {
        if (!dataOptions.loaded) {
          this.lowerNumberOfDataCurrentlyLoading();
        }
        this.currentData.delete(key);
        const uid = this.generateUid(dataOptions.mission, dataOptions.id, dataOptions.secondIdentifier);
        this.removeDataWithUid(uid);
        dataToFetch.set(key, dataOptions);
      } else {
        if (dataOptions.loaded) {
          anyDataLoadedInTheCorrectUnits = true;
        }
      }
    });
    if (!anyDataLoadedInTheCorrectUnits) {
      if (this.layout.yaxis) {
        this.layout.yaxis.range = [0, 0.00001];
      }
    }
    dataToFetch.forEach((value) => {
      this.fetchData(value.mission, value.id, value.secondIdentifier, value.alt_flux_units);
    });
  }

  selectedTimeUnit: string = this.timeUnits[0].value;

  getPlotlyXaxisTypeFromTimeUnit(): AxisType {
    if (!this.isTimeView) {
      return 'linear';
    }
    switch (this.selectedTimeUnit) {
      case 'iso':
        return 'date';
      case 'yday':
        return 'category';
      default:
        return 'linear';
    }
  }
  timeUnitChanged(newUnit: string) {
    this.selectedTimeUnit = newUnit;
    if (this.layout.xaxis != undefined) {
      this.layout.xaxis.type = this.getPlotlyXaxisTypeFromTimeUnit();
    }
    this.unitsHaveBeenChangedByUser = true;
    this.fetchDataWithNewUnits();
  }

  resetIcon = {
    width: 620,
    height: 530,
    svg: [
      '<?xml version="1.0" encoding="UTF-8"?>\n' +
        '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 653.7 640.19">\n' +
        '  <defs>\n' +
        '    <style>\n' +
        '      .cls-1 {\n' +
        '        fill: none;\n' +
        '        stroke-width: 83px;\n' +
        '      }\n' +
        '\n' +
        '      .cls-1, .cls-2 {\n' +
        '        stroke: #efefef;\n' +
        '        stroke-miterlimit: 10;\n' +
        '      }\n' +
        '\n' +
        '      .cls-2 {\n' +
        '        fill: #efefef;\n' +
        '        stroke-width: 55px;\n' +
        '      }\n' +
        '    </style>\n' +
        '  </defs>\n' +
        '  <path class="cls-1" d="M239.5,70C134.49,106.13,66.82,216.23,84.18,333.13c19.38,130.54,137.46,220.12,263.74,200.08,126.28-20.04,212.93-142.11,193.55-272.65-7.47-50.32-29.61-94.55-61.23-128.77"/>\n' +
        '  <polygon class="cls-2" points="530.73 86.42 398.13 77.63 421.76 212.8 530.73 86.42"/>\n' +
        '</svg>'
    ].join('')
  };
  data: any = [];
  config: Partial<Plotly.Config> = {
    displaylogo: false,
    modeBarButtonsToAdd: [
      {
        name: 'reset axes',
        title: 'Reset axes',
        icon: this.resetIcon,
        click: () => {
          if (this.layout.yaxis && this.layout.xaxis) {
            this.layout.yaxis.autorange = true;
            this.layout.xaxis.autorange = true;
            this.plotly?.redraw(this.divId);
          }
        }
      }
    ]
  };

  layout: Partial<Plotly.Layout> = {
    autosize: true,
    showlegend: true,
    margin: {
      l: 110,
      r: 0,
      b: 80,
      t: 70,
      pad: 1
    },
    colorway: [
      '#4fa7e4',
      '#ffaf3e',
      '#5cd05c',
      '#f65758',
      '#c497ed',
      '#bc867b',
      '#f3a7f2',
      '#afafaf',
      '#eced52',
      '#47eeff'
    ],

    paper_bgcolor: '#000000',
    plot_bgcolor: '#000000',
    hoverlabel: {
      bgcolor: '#FFFFFF',
      font: {
        color: '#000000'
      }
    },
    xaxis: {
      title: 'Time',
      showline: false,
      autorange: true,
      zeroline: false,
      titlefont: {
        family: 'Arial, sans-serif',
        size: 12
      },
      color: '#FFFFFF'
    },
    yaxis: {
      title: 'Flux',
      autorange: false,
      titlefont: {
        family: 'Arial, sans-serif',
        size: 12
      },
      exponentformat: 'power',
      showline: false,
      zeroline: false,
      color: '#FFFFFF',
      range: [0, 0.00001]
    },
    title: {
      text: '',
      font: {
        size: 14,
        color: '#FFFFFF'
      }
    },

    legend: {
      font: {
        color: '#EEEEEE'
      },
      groupclick: 'toggleitem'
    },
    modebar: {
      bgcolor: '#000000',
      color: '#DDDDDD',
      activecolor: '#FFFFFF',
      remove: ['autoScale2d', 'lasso2d', 'select2d', 'zoomIn2d', 'zoomOut2d', 'resetScale2d']
    }
  };

  /*
  Using Angular Elements to create timeviz element. This is a workaround to be able to use the component in
  non-angular applications. To create and append timeviz to your application you need to do something like this:
    const timevizElement = document.createElement('timeviz-element')
    document.body.appendChild(timevizElement);

  The @Input fields are the API and you can add data by setting the addData attribute like this:
    timevizElement.addData = ['Gaia-DR3, ''Gaia+DR3+4111834567779557376'];
   */
  @Input()
  set addData(dataInfo: string[]) {
    if (dataInfo.length === 0) {
      return;
    }
    const mission = dataInfo[0];
    const id = dataInfo[1];
    const secondIdentifier = dataInfo[2];
    if (mission.toLowerCase().includes('gaia-dr3')) {
      this.fetchData('Gaia-DR3', id);
    } else if (mission.toLowerCase().includes('jwst-mid-ir')) {
      this.fetchData('JWST-MID-IR', id);
    } else if (mission.toLowerCase().includes('xmm-epic')) {
      this.fetchData('XMM-EPIC', id);
    } else if (mission.toLowerCase().includes('xmm-om')) {
      this.fetchData('XMM-OM', id, secondIdentifier);
    } else if (mission.toLowerCase().includes('cheops')) {
      this.fetchData('CHEOPS', id, secondIdentifier);
    }
  }

  @Input()
  set removeData(dataInfo: string[]) {
    if (dataInfo.length === 0) {
      return;
    }
    const mission = dataInfo[0];
    const id = dataInfo[1];
    const secondIdentifier = dataInfo[2];
    if (mission.toLowerCase().includes('gaia-dr3')) {
      this.doRemoveData('Gaia-DR3', id);
    } else if (mission.toLowerCase().includes('jwst-mid-ir')) {
      this.doRemoveData('JWST-MID-IR', id);
    } else if (mission.toLowerCase().includes('xmm-epic')) {
      this.doRemoveData('XMM-EPIC', id);
    } else if (mission.toLowerCase().includes('xmm-om')) {
      this.doRemoveData('XMM-OM', id, secondIdentifier);
    } else if (mission.toLowerCase().includes('cheops')) {
      this.doRemoveData('CHEOPS', id, secondIdentifier);
    }
  }

  addResizeObserver() {
    const observer: ResizeObserver = new ResizeObserver(() => {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.updatePlotlySize(plotlyPlot);
      }, 400);
    });
    const plotlyPlot = document.getElementById(this.divId);
    if (plotlyPlot != null) {
      plotlyPlot.style.height = '100%';
      observer.observe(plotlyPlot);
    }
  }

  private updatePlotlySize(plotlyPlot: HTMLElement | null) {
    if (plotlyPlot != null) {
      this.plotly.update(this.divId, {}, { height: plotlyPlot.clientHeight, width: plotlyPlot.clientWidth });
    }
  }

  constructor(private plotlyService: PlotlyService, private http: HttpClient) {
    plotlyService.getPlotly().then((plotly) => {
      this.plotly = plotly;
      this.addResizeObserver();
    });
    this.data = [];
  }

  isNumbers(array: Plotly.Datum[] | Plotly.TypedArray | Plotly.Datum[][] | undefined): array is number[] {
    // Casting array to number. Checking more than index 0 to deal with potential null values.
    return (
      array !== undefined &&
      (typeof array[0] === 'number' ||
        typeof array[array.length - 1] === 'number' ||
        typeof array[Math.max(1, Math.floor(array.length / 2)) - 1] === 'number')
    );
  }

  populateUrlParameters(baseUrl: string, id: string, secondIdentifier?: string | null): string {
    secondIdentifier = secondIdentifier || '';
    return baseUrl
      .replace('{id}', id)
      .replace('{secondIdentifier}', secondIdentifier)
      .replace('{selectedTimeUnit}', this.selectedTimeUnit)
      .replace('{selectedFluxUnit}', this.selectedFluxUnit)
      .replace('{selectedTimeScale}', this.selectedTimeScale)
      .replace('{isTimeView}', this.isTimeView.toString());
  }

  // We put this string as uid in the plotly data so that we can iterate over that data and remove
  // specific data. Plotly uses the uid when doing a querySelector() call. That means the string
  // can't have any slashes or spaces (in some cases).
  generateUid(mission: string, id: string, productUrl?: string | null): string {
    if (productUrl) {
      const parts = productUrl.split('/');
      return [mission, id, parts[parts.length - 1]].join('-').replace(/ /g, '-');
    } else {
      return [mission, id].join('-').replace(/ /g, '-');
    }
  }

  setDefaultTimeUnit(mission: string) {
    this.selectedTimeUnit = this.sourceUrls.get(mission)?.defaultTimeUnit || this.selectedTimeUnit;
  }

  setDefaultFluxUnit(mission: string) {
    this.selectedFluxUnit = this.sourceUrls.get(mission)?.defaultFluxUnit.value || this.selectedFluxUnit;
  }

  fetchData(
    mission: string,
    id: string,
    secondIdentifier?: string | undefined,
    oldAlternativeFluxUnits?: string[]
  ): void {
    const missionOptions = this.sourceUrls.get(mission);
    if (missionOptions === undefined) {
      console.warn('No url found for mission: ' + mission);
      return;
    }
    const wasCombinedMissionsView = this.isCombinedMissionsView;
    this.isCombinedMissionsView = false;
    if (this.currentData.size > 0) {
      this.currentData.forEach((value) => {
        if (value.mission !== mission) {
          this.isCombinedMissionsView = true;
        }
      });
    }
    if (this.currentData.size == 0 && !this.unitsHaveBeenChangedByUser) {
      this.setDefaultTimeUnit(mission);
      this.setDefaultFluxUnit(mission);
    }
    if (
      (this.isCombinedMissionsView && isMissionSpecificFluxUnit(this.selectedFluxUnit)) ||
      (!this.isTimeView && this.selectedFluxUnit.toLowerCase().includes('mag'))
    ) {
      this.selectedFluxUnit = mJy.value;
    }
    if (this.selectedTimeScale === '') {
      this.selectedTimeScale = this.sourceUrls.get(mission)?.defaultTimeScale || this.selectedTimeScale;
    }
    const urlWithParameters = this.populateUrlParameters(missionOptions.url, id, secondIdentifier);
    const uid = this.generateUid(mission, id, secondIdentifier);
    if (this.currentData.has(urlWithParameters)) {
      console.warn('Data is already loading for ' + mission + ' with id ' + id);
      return;
    }
    const altFluxUnit = oldAlternativeFluxUnits || [];
    this.currentData.set(urlWithParameters, {
      mission: mission,
      id: id,
      loaded: false,
      selectedTimeUnit: this.selectedTimeUnit,
      selectedFluxUnit: this.selectedFluxUnit,
      selectedTimeScale: this.selectedTimeScale,
      isTimeView: this.isTimeView,
      alt_time_units: [],
      alt_flux_units: altFluxUnit,
      secondIdentifier: secondIdentifier,
      product_url: undefined,
      data: undefined
    });
    this.currentData = new Map(this.currentData); // This is needed to trigger reactivity in child components

    if (this.isCombinedMissionsView && !wasCombinedMissionsView) {
      this.fetchDataWithNewUnits();
    }
    this.isLoading = true;
    this.numberOfDataCurrentlyLoading++;
    this.http
      .get<{
        data: [Partial<Plotly.PlotData>];
        layout: Partial<Plotly.Layout>;
        alt_time_units: string[];
        alt_flux_units: string[];
        url: string;
      }>(urlWithParameters)
      .subscribe({
        next: (response) => {
          if (this.currentData.has(urlWithParameters) && this.currentData.get(urlWithParameters)?.loaded === false) {
            this.lowerNumberOfDataCurrentlyLoading();
            const dataOption = this.currentData.get(urlWithParameters);
            if (dataOption == undefined) {
              console.error('No data option found for ' + urlWithParameters);
              return;
            }
            dataOption.data = response.data;
            dataOption.data.forEach((data) => {
              data.legendgroup = dataOption.id;
              data.legendgrouptitle = { text: dataOption.id, font: { color: '#BBBBBB' } };
            });
            dataOption.alt_time_units = response.alt_time_units;
            dataOption.alt_flux_units = response.alt_flux_units;
            dataOption.product_url = response.url;
            dataOption.loaded = true;
            this.addAlternateTimeUnits(dataOption.alt_time_units);
            this.currentData = new Map(this.currentData); // This is needed to trigger reactivity in child components
            this.readResponse(response.layout, urlWithParameters, uid);
          }
        },
        error: (error) => {
          this.lowerNumberOfDataCurrentlyLoading();
          console.error('Error fetching data for ' + mission + ' with id ' + id + '. Error: ' + error);
          const dataOptions = this.currentData.get(urlWithParameters);
          if (dataOptions !== undefined) {
            dataOptions.loaded = true;
          }
        }
      });
  }

  private doRemoveData(mission: string, id: string, secondIdentifier?: string | undefined): void {
    const missionOptions = this.sourceUrls.get(mission);
    if (missionOptions === undefined) {
      console.warn('No url found for mission: ' + mission);
      return;
    }
    const urlWithParameters = this.populateUrlParameters(missionOptions.url, id, secondIdentifier);
    const uid = this.generateUid(mission, id, secondIdentifier);
    if (!this.currentData.has(urlWithParameters)) {
      console.warn('Trying to remove data that is not here: ' + mission + ' with id ' + id);
      return;
    }
    this.removeDataWithUid(uid);
    if (this.currentData.get(urlWithParameters)?.loaded === false) {
      this.lowerNumberOfDataCurrentlyLoading();
    }
    this.currentData.delete(urlWithParameters);
    this.currentData = new Map(this.currentData); // This is needed to trigger reactivity in child components
    if (this.layout.yaxis) {
      this.layout.yaxis.autorange = true;
    }
    if (this.currentData.size === 0) {
      if (this.layout.yaxis) {
        this.layout.yaxis.range = [0, 0.00001];
      }
    }
  }

  private removeDataWithUid(uid: string) {
    for (let i = this.data.length - 1; i >= 0; i--) {
      if (this.data[i].uid == uid) {
        this.data.splice(i, 1);
      }
    }
  }

  private addAlternateTimeUnits(alt_time_units: string[]): void {
    for (let i = 0; i < alt_time_units.length; i++) {
      if (!this.timeUnits.some((unit) => unit.value === alt_time_units[i])) {
        this.timeUnits.push({ value: alt_time_units[i], viewValue: alt_time_units[i].toUpperCase().replace('_', ' ') });
      }
    }
  }
  private lowerNumberOfDataCurrentlyLoading() {
    this.numberOfDataCurrentlyLoading--;
    if (this.numberOfDataCurrentlyLoading === 0) {
      this.isLoading = false;
    }
  }

  private readResponse(layout: Partial<Plotly.Layout>, urlWithParameters: string, uid: string): void {
    if (layout.yaxis && this.layout.yaxis && layout.yaxis.autorange) {
      this.layout.yaxis.autorange = layout.yaxis.autorange;
    }
    this.animateInDataArray(urlWithParameters, uid);
    if (this.layout.xaxis && this.layout.yaxis) {
      if (layout.xaxis && layout.yaxis) {
        this.layout.xaxis.title = layout.xaxis.title;
        this.layout.yaxis.title = layout.yaxis.title;
        if (layout.yaxis.autorange) {
          this.layout.yaxis.autorange = layout.yaxis.autorange;
        }
      }
      this.layout.xaxis.autorange = true;
    }
  }

  private isAnimating: boolean = false;
  private animationQueue: [string, string][] = [];
  private animateInDataArray(urlWithParameters: string, uid: string): void {
    if (this.isAnimating) {
      this.animationQueue.push([urlWithParameters, uid]);
      return;
    }
    this.isAnimating = true;
    const dataToAdd: [Partial<Plotly.PlotData>] = this.currentData.get(urlWithParameters)?.data || [{}];
    let tooBigToAnimate: boolean = false;
    dataToAdd.forEach((newData) => {
      newData.uid = uid;
      if (newData.y !== undefined && newData.y.length > 10000) {
        tooBigToAnimate = true;
      }
    });
    this.plotly?.deleteFrames(this.divId);

    const allData: [Partial<Plotly.PlotData>] = this.data.concat(dataToAdd);
    const setDataFunction = () => this.setData(this.data, dataToAdd, tooBigToAnimate, () => this.checkQueue());
    this.setZoom(allData, tooBigToAnimate, setDataFunction);
  }

  private setZoom(data: Partial<Plotly.PlotData>[], tooBigToAnimate: boolean, next: () => void) {
    let changedZoom: boolean = false;
    if (!this.layout.yaxis || !this.layout.yaxis.range) {
      return;
    }
    let { min, max } = this.getYaxisMaxMin(data);
    let previousMin: number = Number.MAX_VALUE;
    let previousMax: number = Number.MIN_VALUE;
    if (typeof this.layout.yaxis.range[0] === 'number' && typeof this.layout.yaxis.range[1] === 'number') {
      previousMin = Math.min(this.layout.yaxis.range[0], this.layout.yaxis.range[1]);
      previousMax = Math.max(this.layout.yaxis.range[0], this.layout.yaxis.range[1]);
    }

    changedZoom = min != previousMin || max != previousMax;
    if (changedZoom) {
      max = max + (max - min) * 0.03;
      min = min - (max - min) * 0.03;
    }
    console.log('min: ' + min + ' max: ' + max + ' previousMin: ' + previousMin + ' previousMax: ' + previousMax);

    console.log('changedZoom: ' + changedZoom);
    const animationZoomStartValue = this.layout.yaxis?.autorange;
    const range: number[] = animationZoomStartValue === 'reversed' ? [max, min] : [min, max];

    if (!changedZoom) {
      next();
      return;
    }
    if (tooBigToAnimate) {
      this.layout.yaxis.autorange = animationZoomStartValue;
      this.layout.yaxis.range = range;
      next();
      return;
    }
    this.plotly?.addFrames(this.divId, [
      {
        layout: {
          yaxis: { range: range, autorange: changedZoom }
        },
        name: 'zoom'
      }
    ]);
    this.plotly?.animate(this.divId, ['zoom'], {
      frame: [{ duration: this.zoomDuration }],
      transition: [{ duration: this.zoomDuration, easing: 'cubic-in-out' }],
      mode: 'afterall'
    });
    setTimeout(next, this.zoomDuration);
  }

  private setData(oldData: any[], dataToAdd: any[], tooBigToAnimate: boolean, next: () => void) {
    if (tooBigToAnimate) {
      const allData = oldData.concat(dataToAdd);
      this.data = allData;
      this.isAnimating = false;
      next();
      return;
    }
    // To force plotly to animate in data, we need to set the y values to 0 and then animate to the new values
    const animationTargetValues: any[] = oldData.concat([]);
    const animationStartValues: any[] = oldData.concat([]);
    dataToAdd.forEach((newData) => {
      animationTargetValues.push(newData);
      const zeroArray = newData.y === undefined ? [] : new Array<number>(newData.y.length).fill(0);
      animationStartValues.push({ ...newData, y: zeroArray, error_y: { type: 'data', array: zeroArray } });
    });
    this.plotly?.addFrames(this.divId, [
      {
        data: animationTargetValues,
        name: 'allData'
      }
    ]);
    this.data = animationStartValues;
    setTimeout(() => {
      this.plotly?.animate(this.divId, ['allData'], {
        frame: [{ duration: this.dataAnimationDuration }],
        transition: [{ duration: this.dataAnimationDuration, easing: 'cubic-out' }],
        mode: 'afterall'
      });
      setTimeout(() => {
        this.isAnimating = false;
        next();
      }, this.dataAnimationDuration + 200);
    }, 10);
  }

  private checkQueue() {
    const job = this.animationQueue.shift();
    if (job) {
      const [urlWithParameters, uid] = job;
      this.animateInDataArray(urlWithParameters, uid);
    } else {
      if (this.layout.yaxis) {
        this.layout.yaxis.autorange = true;
      }
    }
  }

  private getYaxisMaxMin(plotData: Partial<Plotly.PlotData>[]): {
    min: number;
    max: number;
  } {
    let min: number = Number.MAX_VALUE;
    let max: number = Number.MIN_VALUE;
    plotData.forEach((series: Partial<Plotly.PlotData>) => {
      if (this.isNumbers(series.y)) {
        let maxError: number = 0;
        if (series.error_y !== undefined && 'array' in series.error_y && this.isNumbers(series.error_y.array)) {
          maxError = Math.max(...series.error_y.array);
        }
        const newMin: number = Math.min(...series.y.filter((value): value is number => value !== null)) - maxError;
        if (newMin < min) {
          min = newMin;
        }
        const newMax: number = Math.max(...series.y) + maxError;
        if (newMax > max) {
          max = newMax;
        }
      }
    });
    return { min, max };
  }

  switchWavelengthTimeView(): void {
    this.isTimeView = !this.isTimeView;
    if (this.layout.xaxis != undefined) {
      this.layout.xaxis.type = this.getPlotlyXaxisTypeFromTimeUnit();
    }
    this.fetchDataWithNewUnits();
  }
  downloadDataProductsClick(): void {
    this.currentData.forEach((dataOptions) => {
      if (dataOptions.product_url && dataOptions.product_url !== '') {
        const target = `Download Data Products ${dataOptions.mission} ${dataOptions.id} ${dataOptions.secondIdentifier}`;
        window.open(dataOptions.product_url, target);
      }
    });
  }
  downloadDataProductsFromTarServerClick(): void {
    const body: string[] = [];
    this.currentData.forEach((dataOptions) => {
      if (dataOptions.product_url && dataOptions.product_url !== '') {
        body.push(dataOptions.product_url);
      }
    });
    const url = 'http://localhost:5001/download?tar=true';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    this.http.post(url, body, { headers, responseType: 'blob' }).subscribe((response: Blob) => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(response);
      a.href = objectUrl;
      a.download = 'files.tar';
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  //Debug buttons
  addDataClick(): void {
    // const data: [Partial<Plotly.PlotData>] = JSON.parse(JSON.stringify(dataFile.data));
    // const layout: Partial<Plotly.Layout> = JSON.parse(JSON.stringify(dataFile.layout));
    // this.readResponse(data, layout);
    this.data = [];
  }
  fetchGaiaDataClick(id: number): void {
    if (id == 0) {
      this.fetchData('Gaia-DR3', 'Gaia DR3 4111834567779557376');
    } else if (id == 1) {
      this.fetchData('Gaia-DR3', 'Gaia DR3 93499857486642304');
    } else if (id == 2) {
      this.fetchData('Gaia-DR3', 'Gaia DR3 6606077966638809216');
    }
  }
  fetchJwstDataClick(): void {
    this.fetchData('JWST-MID-IR', 'jw02783-o002_t001_miri_p750l-slitlessprism');
  }

  fetchXmmOmDataClick(): void {
    this.fetchData('XMM-OM', '0891800501', '001');
  }

  fetchCheopsDataClick(id: number): void {
    if (id == 0) {
      this.fetchData(
        'CHEOPS',
        '1084936',
        'https://skies.esac.esa.int/CheopsData/Lightcurves/CH_PR100037_TG014301_TU2020-04-18T08-38-06_SCI_COR_Lightcurve-DEFAULT_V0300.fits'
      );
    } else if (id == 1) {
      this.fetchData(
        'CHEOPS',
        '1178460',
        'https://skies.esac.esa.int/CheopsData/Lightcurves/CH_PR100036_TG001201_TU2020-07-25T07-14-02_SCI_COR_Lightcurve-DEFAULT_V0300.fits'
      );
    }
  }

  fetchXmmEpicDataClick(): void {
    this.fetchData('XMM-EPIC', '103053615010005');
    // this.fetchData('XMM-EPIC', '103053605010008');
  }
  removeGaiaDataClick(id: number): void {
    if (id == 0) {
      this.doRemoveData('Gaia-DR3', 'Gaia DR3 4111834567779557376');
    } else if (id == 1) {
      this.doRemoveData('Gaia-DR3', 'Gaia DR3 93499857486642304');
    } else if (id == 2) {
      this.doRemoveData('Gaia-DR3', 'Gaia DR3 6606077966638809216');
    }
  }
  removeJwstDataClick(): void {
    this.doRemoveData('JWST-MID-IR', 'jw02783-o002_t001_miri_p750l-slitlessprism');
  }
}
function isMissionSpecificFluxUnit(fluxUnit: string): boolean {
  return (
    fluxUnit === fluxElectronPerSecond.value ||
    fluxUnit === fluxPartsPerMillion.value ||
    fluxUnit === xmmFluxCts.value ||
    fluxUnit.toLowerCase().includes('mag')
  );
}
