import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DataOptions, TimeVizBackendOptions, Unit } from './util/interfaces';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { fluxElectronPerSecond, fluxPartsPerMillion, Jy, xmmFluxCts, mJy } from './util/fluxUnits';

const nameToUnitMap: Map<string, Unit> = new Map(
  [fluxElectronPerSecond, Jy, xmmFluxCts, fluxPartsPerMillion, mJy].map((unit) => [unit.value, unit])
);

@Component({
  standalone: true,
  selector: 'app-flux-unit-selector',
  imports: [FormsModule, MatFormFieldModule, MatSelectModule],
  templateUrl: './fluxUnitSelector.html'
})
export class FluxUnitSelectorComponent {
  @Input()
  get data(): Map<string, DataOptions> {
    return this.dataOptions;
  }

  set data(newData: Map<string, DataOptions>) {
    this.dataOptions = newData;
    this.setAvailableUnits();
    this.maybeSetSelectedUnit();
  }

  @Input()
  isTimeView!: boolean;

  @Input()
  sourceMap!: Map<string, TimeVizBackendOptions>;

  @Output() changeUnit = new EventEmitter<string>();

  dataOptions: Map<string, DataOptions> = new Map();
  availableUnits: Array<Unit> = [];
  selectedFluxUnit: string = mJy.value;

  fluxUnitChanged(newUnit: string) {
    this.selectedFluxUnit = newUnit;
    this.changeUnit.emit(newUnit);
  }

  private setAvailableUnits() {
    if (this.dataOptions.size == 0) {
      this.availableUnits = [];
      return;
    }
    const units: Array<Array<Unit>> = [];
    for (const d of this.dataOptions.values()) {
      if (!d.loaded) {
        continue;
      }
      const unitsForData: Array<Unit> = [];
      const specialUnit = this.sourceMap.get(d.mission)?.defaultFluxUnit;
      if (specialUnit && d.alt_flux_units.indexOf(specialUnit.value) == -1) {
        unitsForData.push(specialUnit);
      }
      if (d.alt_flux_units.length > 0) {
        unitsForData.push(...this.alternativeUnits(d));
      }
      units.push(unitsForData);
    }
    if (units.length == 0) {
      return;
    }
    // Take the intersection of the available sets of units
    let newlyAvailableUnits = units.reduce(
      (acc: Array<Unit>, value: Array<Unit>) => acc.filter((a) => this.containsUnit(value, a)),
      units[0]
    );
    if (this.currentMissions().size > 1) {
      newlyAvailableUnits = newlyAvailableUnits.filter((unit) => unit != fluxElectronPerSecond && unit != xmmFluxCts);
    }
    if (newlyAvailableUnits.length > 0 && !this.arraysEqual(newlyAvailableUnits, this.availableUnits)) {
      this.availableUnits = newlyAvailableUnits;
    }
  }

  private arraysEqual(a: Array<Unit>, b: Array<Unit>): boolean {
    if (a.length !== b.length) return false;
    a.sort((x, y) => x.value.localeCompare(y.value));
    b.sort((x, y) => x.value.localeCompare(y.value));
    return a.every((val, index) => val.value === b[index].value);
  }

  private containsUnit(units: Array<Unit>, unit: Unit): boolean {
    return units.map((v) => v.value).indexOf(unit.value) != -1;
  }

  private maybeSetSelectedUnit() {
    if (this.dataOptions.size == 0) {
      this.selectedFluxUnit = mJy.value;
      return;
    }
    if (this.availableUnits.length == 0) {
      // This (probably) means that the data is currently loading. Set to requested flux unit:
      const requestedUnitValue = this.dataOptions.values().next().value.selectedFluxUnit;
      const unit = nameToUnitMap.get(requestedUnitValue);
      if (unit) {
        this.availableUnits = [unit];
        this.selectedFluxUnit = unit.value;
        return;
      }
    }
    this.selectedFluxUnit = this.dataOptions.values().next().value.selectedFluxUnit;
  }

  private alternativeUnits(dataOptions: DataOptions): Array<Unit> {
    const alternatives: Array<Unit> = [];
    for (let i = 0; i < dataOptions.alt_flux_units.length; i++) {
      const flux_unit = dataOptions.alt_flux_units[i];
      if ((this.currentMissions().size <= 1 && this.isTimeView) || !flux_unit.toLowerCase().includes('mag')) {
        alternatives.push({ value: flux_unit, viewValue: flux_unit.replace('_', ' ') });
      }
    }
    return alternatives;
  }

  currentMissions(): Set<string> {
    const missions: Set<string> = new Set();
    for (const d of this.dataOptions.values()) {
      missions.add(d.mission);
    }
    return missions;
  }
}
